/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import ViewSDKClient from "./ViewSDKClient.js";

const AdobeViewer = (fileUrl) => {

  const pdfFileUrl = fileUrl.fileUrl

  const pdfFileName = fileUrl.fileName

  const referencePageNumber = fileUrl.referencePageNumber;

  const referenceDocumentId = fileUrl.referenceDocumentId;

  const loadPDF = () => {
    const viewSDKClient = new ViewSDKClient();
    viewSDKClient.ready().then(() => {
      viewSDKClient.previewFile(
        "adobe-dc-view",
        {
          embedMode: "FULL_WINDOW",
          defaultViewMode: "FIT_PAGE",
          showAnnotationTools: true,
          showDownloadPDF: true,
          showFullScreen: true
        },
        pdfFileUrl,
        pdfFileName,
        referenceDocumentId,
        referencePageNumber
      );
    });
  }

  useEffect(() => {

    loadPDF();

  }, [pdfFileUrl]); // Depend on clientId and search string

  return <div id="adobe-dc-view"></div>;
};

export default AdobeViewer;
