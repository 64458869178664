
export const VS_PORTAL_API_URL = 'https://portalapi.voicesphere.co'
export const VS_PORTAL_MESSAGE_SOCKET_URL = 'wss://portalapi.voicesphere.co/'
export const VS_ORIGIN_URL = window.location.origin
export const VS_GOOGLE_CLIENTID = "582093292684-3078u9ec559h6rc3trkt27gr2e2trmv3.apps.googleusercontent.com"
export const VS_PRICING_TABLEID = "prctbl_1NvcgdSGtZC3T5or2PvLNSe5"
export const VS_PRICING_PUBLISH_KEY = "pk_live_51NiBVaSGtZC3T5orna786jt5CDs7HoRuhWbcYTOorzlgZEEO6gWPdbpDyG3pFhvyr4W0y6HaEPuNIilkgHLJpDK3000mynv7D8"
export const VS_GOOGLE_CLIENT_SECRET = "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"
export const VS_PDF_EMBED_CLIENT_ID = "ca5a0a739e4e45219dc29d6119c814ac";
export const LAWBUDDY_API_URL = 'https://api.lawe.co.in/api/academic_user/user_verify'

// URL 
export const VS_BLOG_URL = `https://voicesphere.co/blogs/`
export const VS_CONTACT_URL = `https://voicesphere.co/contact/`
export const VS_HELP_URL = `https://voicesphere.co/help/`
export const VS_TERM_OF_SERVICES_URL = `https://voicesphere.co/terms-of-service/`
export const VS_PRIVACY_POLICY_URL = `https://voicesphere.co/privacy-policy/`

export const VS_DEFAULT_ORG_IMAGE = `https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp`
