/* eslint-disable @typescript-eslint/no-explicit-any */
import jwtDecode from 'jwt-decode'
import { getStorage, setStorage } from './storage';
import { message } from 'antd';
import axios from 'axios';
import { headers } from './axios';
import { VS_PORTAL_API_URL } from './constants';

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url?.replace(/-/g, '+')?.replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export const isJwtExpired = (token: string) => {
  const { exp } = parseJwt(token)
  const currentTime = new Date().getTime() / 1000;
  const decodedToken: any = jwtDecode((token))
  const tokenExpiry = (decodedToken.exp * 1000 - 60 * 1000)
  return ((tokenExpiry < new Date().getTime()) || (currentTime > exp))
}

export const isAdmin = (businessid: any) => {
  const roles: any = getStorage('roles');
  const isAdmin = JSON.parse(roles)?.findIndex((roleItem: any) => roleItem.businessId == businessid && (roleItem.roleId == 3 ? roleItem.roleId !== 3 : roleItem.roleId == 1)) !== -1;
  return isAdmin
}
export const isEditor = (businessid: any) => {
  const roles = getStorage('roles');
  if (roles) {
    const parsedRoles = JSON.parse(roles);
    const isEditor = parsedRoles.findIndex((roleItem: any) =>
      roleItem.businessId == businessid && roleItem.roleId == 2) !== -1;
    return isEditor;
  }
  return false;
}

export const setBusinessData = () => {
  const userId = getUserId();
  axios
    .get(`${VS_PORTAL_API_URL}/users/${userId}`, { headers: headers() })
    .then((response: { data: { success: any; objects: any; message: string; }; }) => {
      if (response.data.success) {
        setStorage(`${getUserStorageType()}roles`, JSON.stringify(response.data.objects?.roles || []));
        setStorage(`${getUserStorageType()}imageUrls`, JSON.stringify(response.data.objects?.imageUrls || []));
        setStorage(`${getUserStorageType()}businesses`, JSON.stringify(response.data.objects?.businesses || []));
        setStorage(`${getUserStorageType()}collections`, JSON.stringify(response.data.objects?.collections || []));
        if (response.data.objects?.collections?.length) {
          const routeCollectionId = getStorage('iframeRouterCollectionId')
          const defaultCollection = response.data.objects?.collections.filter((collectionItem: any) => collectionItem.id == routeCollectionId)?.[0]
          const defaultBusiness = response.data.objects?.businesses.filter((businessesItem: any) => businessesItem.id == defaultCollection.businessId)?.[0];
          setStorage(`${getUserStorageType()}businessName`, defaultBusiness.name);
          setStorage(`${getUserStorageType()}businessImageUrl`, defaultBusiness.imageUrl);
          setStorage(`${getUserStorageType()}businessId`, defaultCollection.businessId)
          setStorage(`${getUserStorageType()}businessIsSubscribed`, defaultBusiness.IsSubscribed)
          setStorage(`${getUserStorageType()}collectionName`, defaultCollection.name)
          setStorage(`${getUserStorageType()}collectionId`, defaultCollection.id)
        }
      } else {
        message.error(response?.data.message)
      }
    }).catch((err: { response: { data: { success: any; message: string; }; }; }) => {
      if (!err?.response?.data?.success) {
        message.error(err?.response?.data.message)
      }
    });
}


export const setCurrentUserData = () => {
  const userId = getUserId();
  axios
    .get(`${VS_PORTAL_API_URL}/users/${userId}`, { headers: headers() })
    .then((response: { data: { success: any; objects: any; message: string; }; }) => {
      if (response.data.success) {
        setStorage(`${getUserStorageType()}roles`, JSON.stringify(response.data.objects?.roles || []));
        setStorage(`${getUserStorageType()}imageUrls`, JSON.stringify(response.data.objects?.imageUrls || []));
        setStorage(`${getUserStorageType()}businesses`, JSON.stringify(response.data.objects?.businesses || []));
        setStorage(`${getUserStorageType()}collections`, JSON.stringify(response.data.objects?.collections || []));
        if (response.data.objects?.collections?.length) {
          const routeCollectionId = getStorage('iframeRouterCollectionId')
          const defaultCollection = response.data.objects?.collections.filter((collectionItem: any) => collectionItem.id == routeCollectionId)?.[0]
          const defaultBusiness = response.data.objects?.businesses.filter((businessesItem: any) => businessesItem.id == defaultCollection.businessId)?.[0];
          setStorage(`${getUserStorageType()}businessName`, defaultBusiness.name);
          setStorage(`${getUserStorageType()}businessImageUrl`, defaultBusiness.imageUrl);
          setStorage(`${getUserStorageType()}businessId`, defaultCollection.businessId)
          setStorage(`${getUserStorageType()}businessIsSubscribed`, defaultBusiness.IsSubscribed)
          setStorage(`${getUserStorageType()}collectionName`, defaultCollection.name)
          setStorage(`${getUserStorageType()}collectionId`, defaultCollection.id)
        }

      } else {
        message.error(response?.data.message)
      }
    }).catch((err: { response: { data: { success: any; message: string; }; }; }) => {
      if (!err?.response?.data?.success) {
        message.error(err?.response?.data.message)
      }
    });
}

export const getUserToken = () => {
  return getStorage(`isFromIFrameSession`) ? getStorage(`${getIframeSessionVariable()}token`) : getStorage('token')
}

export const getUserId = () => {
  return getStorage(`isFromIFrameSession`) ? getStorage(`${getIframeSessionVariable()}userId`) : getStorage('userId')
}

export const getUserStorageType = () => {
  return getStorage(`isFromIFrameSession`) ? getIframeSessionVariable() : '';
}

export const getUserRefreshToken = () => {
  return getStorage(`isFromIFrameSession`) ? getStorage(`${getIframeSessionVariable()}refreshToken`) : getStorage('refreshToken')
}

export const getIframeSessionVariable = () => {
  return `iframe_${getStorage('iframeRouterUserId')}_${getStorage('iframeRouterCollectionId')}_`;
}

export const isMobileBrowser = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
