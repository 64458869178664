import { VS_PDF_EMBED_CLIENT_ID } from './utils/constants';

class ViewSDKClient {

    constructor() {
        this.readyPromise = new Promise((resolve) => {
            if (window.AdobeDC) {
                resolve();
            } else {
                document.addEventListener("adobe_dc_view_sdk.ready", () => {
                    resolve();
                });
            }
        });
        this.adobeDCView = undefined;
    }
    ready() {
        return this.readyPromise;
    }
    previewFile(divId, viewerConfig, url, fileName, referenceDocumentId, referencePageNumber) {
        const config = {
            clientId: VS_PDF_EMBED_CLIENT_ID,
        };
        if (divId) {
            config.divId = divId;
        }
        this.adobeDCView = new window.AdobeDC.View(config);
        const previewFilePromise = this.adobeDCView.previewFile({
            content: {
                location: {
                    url: url
                },
            },
            metaData: {
                fileName: fileName,
                hasReadOnlyAccess: true
            }
        }, viewerConfig);

        if (referencePageNumber) {
            previewFilePromise.then((adobeViewer) => {
                adobeViewer.getAPIs().then((apis) => {
                    apis.gotoLocation(parseInt(referencePageNumber))
                });
            });
        }

        window.onhashchange = () => {
            const locationHashSplit = location.hash.split('#')

            if (locationHashSplit[1] !== null && locationHashSplit[1] !== undefined) {
                const pageNumberSplit = locationHashSplit[1].split("***");

                const documentId = pageNumberSplit[0]
                const pageNumber = pageNumberSplit[1] || 1;
                if (referenceDocumentId === documentId && pageNumber) {
                    previewFilePromise.then((adobeViewer) => {
                        adobeViewer.getAPIs().then((apis) => {
                            apis.gotoLocation(parseInt(pageNumber))
                        });
                    });
                }
            }
        }
        return previewFilePromise;
    }
    previewFileUsingFilePromise(divId, filePromise, fileName) {
        this.adobeDCView = new window.AdobeDC.View({
            clientId: VS_PDF_EMBED_CLIENT_ID,
            divId,
        });
        this.adobeDCView.previewFile({
            content: {
                promise: filePromise,
            },
            metaData: {
                fileName: fileName
            }
        }, {});
    }
}
export default ViewSDKClient;